const siteMetadata = {
    title: `Maryland Real Estate School: Prelicensing and Continuing Education`,
    siteUrl: `http://localhost`,
    capitalizeTitleOnHome: false,
    logo: `/images/md-re-school-200x.png`,
    icon: `/images/icon.png`,
    /* titleImage: `/images/wall.png`,
    ogImage: `/images/wall.png`, */
    titleImage: `/images/real-estate-license.png`,
    ogImage: `/images/real-estate-license.png`,
    twoColumnWall: true,
    cookiePolicy: true,
    introTag: `FOLLOW THESE STEPS TO GET YOUR MARYLAND REAL ESTATE LICENSE`,
    description: `Getting your Maryland real estate license requires hard work and dedication though it is a relatively straightforward process. Follow these steps to become a Maryland REALTOR.`,
    about:
        "Maryland Real Estate School, in association with Premier Fine Properties, helps simplify the process of getting your Maryland real estate license and staying compliant with continuing education requirements. With an industry leading 85/15 commission split (capped at $15,000) and the top name in luxury real estate, Premier Fine Properties can help you take your real estate career to the next level. ",
    author: `@_akzhy`,
    blogItemsPerPage: 10,
    portfolioItemsPerPage: 10,
    darkmode: false,
    switchTheme: true,
    navLinks: [
        {
            name: "GET LICENSED",
            url: "/classes/maryland-salesperson-prelicensing",
        },
        {
            name: "CONTINUING EDUCATION",
            url: "/classes/maryland-continuing-education-ce",
        },
        {
            name: "CHOOSING A BROKER",
            url: "/blog/how-to-choose-real-estate-broker",
        },
        {
            name: "MD LICENSING RESOURCES",
            url: "/blog/maryland-real-estate-licensing-resources",
        },
        {
            name: "NATIONAL LICENSING",
            url: "/blog/national-real-estate-licenses",
        },
    ],
    footerLinks: [
        {
            name: "PRIVACY POLICY",
            url: "/privacy-policy",
        },
        {
            name: "PREMIER FINE PROPERTIES",
            url: "https://premfine.com/realtor-careers",
        },
    ],
    social: [
        {
            name: "Facebook",
            icon: "/images/Facebook.svg",
            url: "#",
        },
        {
            name: "Twitter",
            icon: "/images/Twitter.svg",
            url: "#",
        },
        {
            name: "Instagram",
            icon: "/images/Instagram.svg",
            url: "#",
        },
        {
            name: "Youtube",
            icon: "/images/Youtube.svg",
            url: "#",
        },
    ],
    contact: {
        // leave empty ('') or false to hide form
        api_url: "https://getform.io/f/f227a36e-096a-4c6a-9963-9f1918a85bb3",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet accumsan arcu. Proin ac consequat arcu.`,
        mail: "hi@akzhy.com",
        phone: "000-000-0000",
        address: "1234 \nLocation \nLocation",
    },
    disqus: "",
}

const beforeContactFormSubmit = data => {
    // Code 0 - success
    // Code 1 - Name
    // Code 2 - Email
    // Code 3 - Message
    // Code 4 - Other
    const errors = []

    if (data.name.trim().length < 2) {
        errors.push({
            code: 1,
            message: "Enter a name",
        })
    }

    if (!data.email.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)) {
        errors.push({
            code: 2,
            message: "Enter a valid email address",
        })
    }

    if (data.message.trim().length < 15) {
        errors.push({
            code: 3,
            message: "Enter a message with atleast 15 characters",
        })
    }

    if (errors.length > 0)
        return {
            result: false,
            errors: errors,
        }

    return {
        data: {
            name: data.name,
            email: data.email,
            message: data.message,
        },
        result: true,
    }
}

const contactFormSubmit = async (api, data) => {
    let res: any = await fetch(api, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })

    res = await res.json()

    if (res.success) {
        return {
            result: true,
        }
    }
    return {
        result: false,
        ...res,
    }
}

const defaults = {
    disqus: null,
    twoColumnWall: true,
    darkmode: false,
    switchTheme: true,
    capitalizeTitleOnHome: true,
    cookiePolicy: false
}

Object.keys(defaults).forEach(item => {
    if (siteMetadata[item] === undefined) {
        siteMetadata[item] = defaults[item]
    }
})

export { siteMetadata, beforeContactFormSubmit, contactFormSubmit }
